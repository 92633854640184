//import '../utils/wdyr';

import React, { useEffect } from 'react';
import { Routes, Route, Outlet } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import {ToastContainer} from 'react-toastify';
import { connect } from 'react-redux';

import 'bootstrap/dist/css/bootstrap.css';

import "../assets/css/shakers.css";
//import "../assets/css/debanjana.css";
import Loader from './Loader';

import {useWindowResizeListener} from '../utils/useHooks';
import { setMenuState, setMobileState } from '../actions/CategoryAction';
import { getCompanyInfo } from '../actions/HomeAction';

import HomeContainer from './HomeContainer';
import Products from '../components/Products';
import PinModal from '../components/PinModal';
import ProductDetails from '../components/ProductDetails';
import CategoryContainer from './CategoryContainer';
import SearchPanel from './SearchPanel';
import FeaturePanel from './FeaturePanel';
import VariantModal from "../components/VariantModal";
import CheckOut from './CheckOut';
import CartPreviewModal from './CartPreviewModal';
import NotFound from './NotFound'; 

import ScrollToTop from '../components/Scroll';

/* const HomeContainer = lazy(() => import('./HomeContainer'));
const Products = lazy(() => import('../components/Products'));
const PinModal = lazy(() => import('../components/PinModal'));
const ProductDetails = lazy(() => import('../components/ProductDetails'));
const CategoryContainer = lazy(() => import('./CategoryContainer'));
const SearchPanel = lazy(() => import('./SearchPanel'));
const VariantModal = lazy(() => import('../components/VariantModal'));
const CheckOut = lazy(() => import('./CheckOut'));
const CartPreview = lazy(() => import('./CartPreview'));
const NotFound = lazy(() => import('./NotFound')); */

const App = props => {

    /* <nav>
        <ul className="home-menu">
        <li className="home-menuli"><Link to="/"> Home </Link></li> 
        <li className="home-menuli"><Link to="/products"> Products </Link></li> 
        <li className="home-menuli"><Link to="/productdetails"> ProductDetails </Link></li> 
        <li className="home-menuli"><Link to="/checkout"> Checkout  </Link></li> 
        </ul>
    </nav> */
    
    const { width } = useWindowResizeListener();
    const {setMenuState, setMobileState} = props;

    useEffect(() => {
        if (width < 768) {
            setMenuState(false);
            setMobileState(true);
        } else {
            setMenuState(true);
            setMobileState(false);
        }
    }, [width, setMenuState, setMobileState]);

    useEffect(() => {
        console.log("getCompanyInfo-> to call in App.js")
        props.getCompanyInfo();
      }, []);
    
    return (
        <Container fluid="true">
            <ScrollToTop>
                <Routes>
                    <Route path="/" element={<HomeContainer />} >
                        <Route path="products" element={<Outlet />}>
                            <Route path="/" element={<Products />} />
                            <Route path="/:slug" element={<ProductDetails />} />
                            <Route path="*" element={<NotFound />} />
                        </Route>
                        <Route path="/" element={<FeaturePanel />} />
                        <Route path="search" element={<SearchPanel />} />
                        <Route path="category" element={<Outlet />} >
                            <Route path="/" element={<Outlet />} />
                            <Route path="/:catid" element={<CategoryContainer />} />
							<Route path="/:catid/:subcatid" element={<CategoryContainer />} />
                            <Route path="*" element={<NotFound />} />
                        </Route>
                        <Route path="*" element={<NotFound />} />
                    </Route>
                    <Route path="/checkout" element={<CheckOut />} />
                </Routes>
                <PinModal />
                <VariantModal />
                <CartPreviewModal />
                <ToastContainer autoClose={false} />
                <Loader />
            </ScrollToTop>
        </Container>
    )
}


export default connect(null, { getCompanyInfo, setMenuState, setMobileState })(App);
