//.. local run --development 
const company = {
    name: 'Shakers',
    logo:'shakers_logo.png'
};

const dev_app = {
    COMPANY_URL: "https://devb2c.wizardcomm.in/?companyid=shakersdaily",
    API_URL: "https://admin.wizardcomm.in/serviceb2c/api/",
    IMG_URL: "https://devb2c.wizardcomm.in/images/"
};

const qa_app = {
    COMPANY_URL: "ecommerceqa.wizardcomm.net",
    API_URL: "https://ecommerceqa.wizardcomm.net/api/",
    IMG_URL: "https://ecommerceqa.wizardcomm.net/images/"
};

/* for shakers */
const stage_app = {
    COMPANY_URL: "https://stage.uvanij.com/?company=shakersdaily",
    API_URL: "https://stageadmin.uvanij.com/API/api/",
    IMG_URL: "https://stage.uvanij.com/images/shakersdaily/"
};

/* for debanjana */
const debanjana_app = {
    COMPANY_URL: "https://debanjanaboutique.uvanij.com/",
    API_URL: "https://stageadmin.uvanij.com/API/api/",
    IMG_URL: "https://debanjanaboutique.uvanij.com/images/"
};

/* for suswadu */
const suswaducaterer_app = {
    COMPANY_URL: "https://suswaducaterer.uvanij.com/",
    API_URL: "https://stageadmin.uvanij.com/API/api/",
    IMG_URL: "https://suswaducaterer.uvanij.com/images/"
};

const prod_app = {
    COMPANY_URL: "shakersdaily.uvanij.com",
    API_URL: "https://admin.uvanij.com/API/api/",
    IMG_URL: "https://shakersdaily.uvanij.com/images/"
};

let configured = {company};

if(process.env.NODE_ENV === 'development'){
    //.. local dev env:: preconfigured
    configured = {...configured, app:suswaducaterer_app, cacheTimeOut: 0 }; // CHANGE FOR LOCAL DEVELOPMENT
}else{
    //.. get app related details from .env file
    configured = {...configured, app:{
        API_URL: process.env.REACT_APP_API_URL,
        IMG_URL: process.env.REACT_APP_IMG_URL
    },
    cacheTimeOut:parseInt(process.env.REACT_APP_CACHE_INTERVAL) };
}

export const config = configured; 
